<template>
  <div class="mt-5">
    <p class="lead">Trabalhe Conosco</p>

    <p class="text-muted">
      Preencha o formulário abaixo com suas Informações pessoais junto ao seu currículo e  entraremos em contato.
    </p>

    <form ref="form" class="mt-5" @submit.stop.prevent="handleSubmit">
      <div class="row">
        <!-- NOME -->
        <b-form-group
          :state="state.nome"
          label="Nome"
          label-for="nome"
          invalid-feedback="Nome é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="nome"
            v-model="formulario.nome"
            :state="state.nome"
            required
          ></b-form-input>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group
          :state="state.email"
          label="E-mail"
          label-for="email"
          invalid-feedback="E-mail é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="email"
            v-model="formulario.email"
            :state="state.email"
            type="email"
            required
          >
          </b-form-input>
        </b-form-group>

        <!-- TELEFONE -->
        <b-form-group
          :state="state.telefone"
          label="Telefone"
          label-for="telefone"
          invalid-feedback="Telefone é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="telefone"
            v-model="formulario.telefone"
            :state="state.telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            required
          >
          </b-form-input>
        </b-form-group>
      </div>

      <div class="row">
        <!-- EXPERIENCIA -->
        <b-form-group
        :state="state.mensagem"
        label="Fale um pouco de você e suas experiências"
        label-for="mensagem"
        invalid-feedback="Experiência é obrigatória"
        class="col-12 col-md-8"
        >
          <b-form-textarea
          id="mensagem"
          v-model="formulario.mensagem"
          :state="state.mensagem"
          style="height: 100px"
          maxlength="600"
          required
        >
        </b-form-textarea>
        </b-form-group>

        <!--ARQUIVO-->
        <b-form-group
            label="Carregue seu currículo"
            label-for="arquivo"
            invalid-feedback="Currículo é obrigatório"
            class="col-12 col-md-4"
            :state="state.arquivo"
        >
          <b-form-file
              :state="state.arquivo"
              v-model="formulario.arquivo"
              placeholder="Escolha o arquivo ou arraste..."
              drop-placeholder="Solte o arquivo aqui..."
              accept=".doc, .docx, .pdf"
              single
          ></b-form-file>
        </b-form-group>
      </div>

      <b-form-group class="text-right">
        <b-button
          @click="handleOk"
          :disabled="state.form.send"
          class="col-12 col-md-3 mr-0 mr-md-1 mb-1 mb-md-0"
          style="background-color: #152b5e"
          >Enviar</b-button
        >
        <b-button @click="clearForm" class="col-12 col-md-1">Limpar</b-button>
      </b-form-group>
    </form>

    <b-alert
      v-model="notificacaoEnviada"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000; background-color: #152b5e"
      variant="border-0 text-light"
    >
      Mensagem enviada com sucesso!
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificacaoEnviada: false,
      formulario: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
        arquivo: null
      },
      state: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
        arquivo: null,
        form: {
          send: false
        }
      },
    };
  },
  methods: {
    clearForm() {
      this.formulario.nome = null;
      this.formulario.email = null;
      this.formulario.telefone = null;
      this.formulario.mensagem = null;
      this.formulario.arquivo = null;

      this.state.nome = null;
      this.state.email = null;
      this.state.telefone = null;
      this.state.mensagem = null;
      this.state.arquivo = null;
    },
    async handleOk(modalEvent) {
      modalEvent.preventDefault();
      await this.handleSubmit();
    },
    async handleSubmit() {
      this.state.nome = !!this.formulario.nome;
      this.state.email = !!this.formulario.email;
      this.state.telefone = !!this.formulario.telefone;
      this.state.mensagem = !!this.formulario.mensagem;
      this.state.arquivo = !!this.formulario.arquivo;

      if (
          !this.state.nome ||
          !this.state.email ||
          !this.state.telefone ||
          !this.state.mensagem ||
          !this.state.arquivo
      )
        return;

      await this.enviarFormulario(this.formulario);
    },
    async enviarFormulario(data) {
      this.state.form.send = true;

      this.$http
          .post("https://8sv1c2vc0b.execute-api.us-east-2.amazonaws.com/prod/enviar", {
            ...data,
            tipo: "trabalheconosco",
            assunto: `Trabalhe Conosco`,
            arquivo: await this.toBase64(this.formulario.arquivo)
          })
          .then((res) => res.json)
          .then(() => {
            this.notificacaoEnviada = true;
            this.state.form.send = false;
            setTimeout(() => {
              this.notificacaoEnviada = false;
            }, 5000);
            this.clearForm();
          })
          .catch(() => {
            this.state.form.send = false;
          });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      });
    }
  },
  metaInfo: {
    title: "Trabalhe Conosco",
  },
};
</script>

<style>
</style>